import * as React from "react"
import { CloudinaryContext } from "cloudinary-react"
import { Helmet } from "react-helmet"
import { Router } from "@reach/router"
import { Dashboard } from "../dashboard/Home"
import { ProtectedRoute } from "../dashboard/routing"
import { SearchConnect } from "../dashboard/SearchConnect"
import { Settings } from "../dashboard/Settings"

const NotFound = () => <p>Page not found</p>

const AccountPage = () => {
  return (
    <>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <CloudinaryContext cloudName="dnecdqjwp">
        <Router>
          <NotFound default />
          <ProtectedRoute component={Dashboard} path="/account" />
          <ProtectedRoute component={Settings} path="/account/settings" />
          <ProtectedRoute
            component={SearchConnect}
            path="/account/search-and-connect"
          />
        </Router>
      </CloudinaryContext>
    </>
  )
}

export default AccountPage
