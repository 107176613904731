import React from "react"
import { Link } from "@reach/router"

function Navigation({ children, accountStatus }) {
  if (
    accountStatus.type === "lapsed" &&
    accountStatus.role === "lapsed" &&
    !accountStatus.current_period_start
  ) {
    return null
  }

  return (
    <>
      <nav className="hidden justify-end font-semibold lg:flex items-center">
        <Link
          to="/account"
          className="px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
        >
          Membership
        </Link>
        <Link
          to="/account/settings"
          className="px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
        >
          Your Profile
        </Link>
        {accountStatus.role !== "lapsed" && accountStatus.role !== "free" && (
          <>
            <Link
              to="/account/search-and-connect"
              className="px-3 xl:px-4 py-2 text-gray-700 hover:text-black hover:bg-gray-200 rounded"
            >
              Search &amp; Connect
            </Link>
          </>
        )}
      </nav>
      {children}
    </>
  )
}
export default Navigation
