import * as React from "react"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "@reach/router"
import { useAuth } from "../providers/auth/AuthProvider"
import {
  EmailNotice,
  ManualRenewalNotice,
  PendingNotice,
  ReferenceNotice,
  PaymentNotice,
} from "../components/notice"

import PageLoader from "../components/page-loader"
import getUserRecord from "../bssr-api/auth0/get-user"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Referees from "../components/account/Referees"
import Navigation from "../components/account/pages/Navigation"
import ProfileBanner from "../components/profile-banner"
import RequestedReferences from "../components/account/RequestedReferences"
import isInRenewalPeriod from "../utils/isInRenewalPeriod"

export const Dashboard = () => {
  const { getIdTokenClaims } = useAuth0()
  const [state, dispatch] = useAuth()
  const [loading, setLoading] = useState(true)
  const [portalLoading, setPortalLoading] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash) {
        window.location.hash = ""
      }
    }

    getIdTokenClaims()
      .then((res) => {
        if (Object.keys(state).length === 0 && typeof window !== "undefined") {
          const idToken = res.__raw

          getUserRecord(res.sub, idToken)
            .then((res) => {
              if (res.data) {
                dispatch({ type: "setUser", payload: { ...res.data, idToken } })
                setLoading(false)
              } else {
                console.error("getUserData() issue with res", res)
              }
            })
            .catch((err) => {
              console.error("getUserData() error", err)
            })
        } else {
          setLoading(false)
        }
      })
      .catch((err) => console.error("err", err))
  }, [])

  // Requested References
  const requestedReferences = state.app_metadata
    ? state.app_metadata.references_requested
    : []

  if (loading) {
    return (
      <Layout>
        <Seo title="Your Dashboard" />
        <PageLoader />
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Your Dashboard" />
      <div className="container mx-auto px-1 py-3 lg:py-4 lg:flex items-start justify-end">
        <Navigation
          accountStatus={{
            current_period_start: state.app_metadata.current_period_start,
            type: state.app_metadata.account_type,
            role: state.app_metadata.role,
          }}
        />
      </div>

      {!state.email_verified && <EmailNotice user={state} />}
      {state.email_verified &&
        state.app_metadata.account_type === "free" &&
        state.app_metadata.references_requested.length < 2 && (
          <ReferenceNotice />
        )}

      {state.app_metadata.account_type === "pending" && <PendingNotice />}

      {state.app_metadata.force_manual_renewal &&
        isInRenewalPeriod(state.app_metadata.current_period_end) && (
          <ManualRenewalNotice
            link={`/register/payment?uid=${state.user_id.replace(
              "auth0|",
              ""
            )}`}
            date={state.app_metadata.current_period_end}
          />
        )}

      {state.app_metadata.account_type === "free" &&
        requestedReferences.filter((r) => r.status === "approved").length ===
          2 && <PaymentNotice />}

      <div>
        <ProfileBanner profile={state} token={state.idToken} />

        <div className="container mx-auto -mt-16">
          <div className="max-w-5xl mx-auto bg-white shadow-xl px-5 pt-3 pb-6 md:rounded-t-lg">
            {state.app_metadata.account_type !== "retired" && (
              <>
                <h3 className="uppercase font-bold tracking-wide text-gray-600 py-3 border-b-2 border-gray-400">
                  Manage your membership
                </h3>

                {state.email_verified &&
                  (state.app_metadata.account_type !== "free" ? null : (
                    <Referees token={state.idToken} />
                  ))}

                {state.app_metadata.account_type !== "free" &&
                  state.app_metadata.account_type !== "retired" && (
                    <>
                      <h4 className="uppercase font-bold tracking-wide text-gray-600 py-2 pt-4 mb-4">
                        Your Subscription
                      </h4>

                      {state.app_metadata.role === "lapsed" && (
                        <div className="bg-yellow-200 border-yellow-400 border-2 rounded p-4 mb-4">
                          <h4 className="uppercase font-bold tracking-wide text-yellow-800 mb-2">
                            Your Membership Has Lapsed
                          </h4>
                          <p className="mb-0 text-yellow-800">
                            Your membership has now lapsed. To re-activate your
                            membership please{" "}
                            <Link
                              className="font-bold underline"
                              to={`/register/payment?uid=${state.user_id.replace(
                                "auth0|",
                                ""
                              )}`}
                            >
                              click here to re-subscribe.
                            </Link>
                          </p>
                        </div>
                      )}

                      {state.app_metadata.role !== "lapsed" && (
                        <>
                          <p className="mb-4">
                            You can manage your BSSR Subscription and payments
                            through a dedicated portal, which you can access by
                            clicking the button below.
                          </p>

                          <p>Use this portal to:</p>

                          <ul className="list-disc pl-8 my-4">
                            <li>Update your payment details</li>
                            <li>View your billing history</li>
                            <li>Renew or Terminate your Subscription</li>
                          </ul>

                          <div className="mt-6">
                            <button
                              disabled={portalLoading}
                              className={classNames(
                                "px-1 lg:px-4 py-2 ml-1 mr-0 font-bold rounded",
                                {
                                  "bg-gray-200 text-black cursor-not-allowed":
                                    portalLoading,
                                  "bg-green-700 text-white hover:bg-green-900":
                                    !portalLoading,
                                }
                              )}
                              onClick={async () => {
                                setPortalLoading(true)

                                try {
                                  const res = await fetch(
                                    `/.netlify/functions/create-portal-session?customerId=${state.app_metadata.stripe_id}`,
                                    {
                                      method: "GET",
                                      headers: {
                                        Accept: "application/json",
                                        Authorization: `Bearer ${state.idToken}`,
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )

                                  if (res.ok) {
                                    const json = await res.json()

                                    if (typeof window !== "undefined") {
                                      window.location = json.data.session.url
                                    }
                                  }
                                } catch (err) {
                                  alert(
                                    "There was a problem loading the portal. Please try again later."
                                  )

                                  console.error("Unable to load portal", err)
                                  setPortalLoading(false)
                                }
                              }}
                            >
                              {portalLoading
                                ? "Please wait..."
                                : "Open Payment & Subscription Portal"}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </>
            )}

            <RequestedReferences token={state.idToken} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
